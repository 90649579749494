import firebase from "../database/firebase";
import { useEffect, useState } from "react";

const Usuarios = () => {
  const [activeCursos, setActiveCursos] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const GetUsers = async () => {
    await firebase
      .firestore()
      .collection("users")
      .get()
      .then((querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });
        setActiveCursos(docs);
      });
  };

  useEffect(() => {
    GetUsers();
  }, []);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        let email = user.email;
        if (email === "wellingthonaquino54@gmail.com" || email === "juan@brandpetram.com") {
          setLoaded(true);
        } 
      }
    });
  }, []);

  const EliminarUsuario = async (id) => {
    await firebase.firestore().collection("users").doc(id).delete();
    GetUsers();
  };

  return (
    <>
      {loaded ? (
        <div className="flex flex-col w-10/12 mx-auto py-10">
          <a
            href="/crear-usuario"
            className="text-white text-center font-bold bg-blue-700 mb-5 rounded-md p-2"
          >
            Crear usuario
          </a>
          <a
            href="/enviar-email"
            className="text-white text-center font-bold bg-green-700 mb-5 rounded-md p-2"
          >
            Enviador de correo
          </a>
          
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Ultimo acceso
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Reiniciar acceso
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {activeCursos.map((person, personIdx) => (
                      <tr
                        key={person.email}
                        className={
                          personIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                        }
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {person.email}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {new Date(
                            person.lastLogin.seconds * 1000
                          ).toLocaleString()}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <button onClick={(e) => EliminarUsuario(person.id)} className="py-2 px-2 bg-indigo-700 text-white font-bold rounded">
                            Reiniciar acceso
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col w-10/12 mx-auto py-10">
          <h1>NO AUTORIZADO</h1>
        </div>
      )}
    </>
  );
};

export default Usuarios;
