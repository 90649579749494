import React, { useEffect } from "react";
import { Routes ,Route } from 'react-router-dom';
import Dashboard from "./components/dashboard";
import Login from "./components/login";
import Usuarios from "./pages/usuarios.js"
import CrearUsuarios from "./pages/crearUsuario"
import PlantillasEditables from "./pages/plantillas-editables"
function App() {
  return (
    <div className="bg-gray-100">
     <Routes>
        <Route path='/enviar-email' element={<Dashboard/>} />
        <Route path='/' element={ <Dashboard/>} />
        <Route path='/usuarios' element={<Usuarios/>} />
        <Route path='/crear-usuario' element={<CrearUsuarios/>} />
        <Route path='/plantillas-editables' element={<PlantillasEditables/>} />
      </Routes> 
    </div>
  );
}

export default App;
