import { useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import SendEmail from "../components/SendPlantilla";
import firebase from "../database/firebase";

const navigation = [{ name: "Cerrar sesión", href: "#", current: true }];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PlantillasEditables() {
 
  return (
    <>
      {true ? (
        <div className="min-h-full">
          <Disclosure as="nav" className="bg-gray-800">
            {({ open }) => (
              <>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="flex items-center justify-between h-16">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <img
                          className="h-8 w-8"
                          src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                          alt="Workflow"
                        />
                      </div>
                      <div className="hidden md:block">
                        <div className="ml-10 flex items-baseline space-x-4">
                          {navigation.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              onClick={(e) => firebase.auth().signOut()}
                              className={classNames(
                                item.current
                                  ? "bg-gray-900 text-white"
                                  : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                "px-3 py-2 rounded-md text-sm font-medium"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </a>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="hidden md:block"></div>
                  </div>
                </div>
              </>
            )}
          </Disclosure>

          <header className="bg-gray-100 shadow-sm">
            <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex gap-x-10">
              <a
                href="/"
                className="text-lg leading-6 font-semibold text-gray-900"
              >
                Inicio
              </a>
              <a
                href="/plantillas-editables"
                className="text-lg leading-6 font-semibold text-gray-900"
              >
                Plantillas editables
              </a>
            </div>
          </header>
          <main>
            <div className="w-11/12 mx-auto py-6 sm:px-6 lg:px-8">
              {/* Replace with your content */}
              <SendEmail />
              {/* /End replace */}
            </div>
          </main>
        </div>
      ) : (
        <div className="h-screen w-full text-center grid content-center">
          <p>Esperando aprobación de accesso . . .</p>
        </div>
      )}
    </>
  );
}
