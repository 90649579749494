import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database'
import 'firebase/compat/storage';  // <----
import { arrayUnion} from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBzZ02NqYvsjF1x76CQuFNpWxtBoZGQfHc",
  authDomain: "email-474c6.firebaseapp.com",
  projectId: "email-474c6",
  storageBucket: "email-474c6.appspot.com",
  messagingSenderId: "498312537720",
  appId: "1:498312537720:web:caae2783e6060ffca2ef8d"
};
// Initialize Firebase
  let principal = firebase.initializeApp(firebaseConfig);

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.


export default principal