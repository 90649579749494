import Swal from "sweetalert2";
import firebase from "../database/firebase";

import { useState, useEffect } from "react";
const CrearUsuarios = () => {
  const [state, setState] = useState([]);
  const [load, setLoad] = useState(false);

  const HanddleWrite = (name, value) => {
    setState({ ...state, [name]: value });
  };
  const createUser = () => {
    var requestOptions = {
      method: "GET",
      mode: "cors",
      header: "Access-Control-Allow-Origin",
    };
    fetch(
      "https://us-central1-email-474c6.cloudfunctions.net/addUser?username=" +
        state.username +
        "&firstname=" +
        state.firstname,
      requestOptions
    )
      .then(() => {
        Swal.fire(
          "Usuario creado",
          "Usuario creado correctamente",
          "success"
        ).then(() => {
          window.location.href = "/usuarios";
        });
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;

        if (errorCode == "auth/email-already-in-use") {
          Swal.fire("El correo que ingreso ya esta en uso, intenté con otro.");
          //alert("El correo que ingreso ya esta en uso, intente con otro.")
        } else if (errorCode == "auth/weak-password") {
          Swal.fire("Su contraseña debe de tener por lo menos 6 caracteres.");
          //alert("Su contraseña debe de tener por lo menos 6 caracteres.")
        } else if (errorCode == "auth/invalid-email") {
          Swal.fire("El formato del correo ingresado no es valido.");
          //alert("El formato del correo ingresado no es valido.")
        } else if (errorCode == "auth/network-request-failed") {
          Swal.fire("Se perdió su conexión a internet, por favor verifique.");
        }
        // ..
      });
  };

  useEffect(() => {
    const user = firebase.auth().currentUser;
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        if (
          user.email == "wellingthonaquino54@gmail.com" ||
          user.email == "juan@brandpetram.com"
        ) {
          setLoad(true);
        }
      }
    });
  }, []);
  return (
    <div className="grid content-center w-full h-screen ">
      {
        load ? (
      <div className="w-8/12 mx-auto bg-white p-10 space-y-5">
        <h1 className="text-2xl text-center">Creación de Usuario </h1>
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email
          </label>
          <div className="mt-1 border p-2">
            <input
              type="email"
              name="email"
              onChange={(e) => {
                HanddleWrite("username", e.target.value);
              }}
              id="email"
              className=" w-full h-full p-1 focus:border-gray-50"
              placeholder="Email del usuario a crear por ejemplo: usuario@correo.com"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700"
          >
            Contraseña
          </label>
          <div className="mt-1 border p-2">
            <input
              type="text"
              onChange={(e) => {
                HanddleWrite("firstname", e.target.value);
              }}
              name="password"
              id="password"
              className=" w-full h-full p-1 focus:border-gray-50"
              placeholder="Contraseña de al menos 6 caractares para el usuario"
            />
          </div>
        </div>
        <button
          onClick={(e) => createUser()}
          className="text-center w-full bg-blue-100 hover:bg-blue-500 hover:text-white p-2 font-bold text-xl"
        >
          Crear usuario
        </button>
      </div>
        ) : (
          <p className="text-center w-full">Acceso restringido</p>
        )
      }
    </div>
  );
};

export default CrearUsuarios;
