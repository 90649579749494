import { useState, useEffect } from "react";
import Swal from "sweetalert2";
const SendEmail = () => {
  const [state, setstate] = useState([]);
  const [url, setUrl] = useState("");
  const [plantilla, setplantilla] = useState(false);

  const correoEstatico = "bncontactodigital@bncr.ficr";

  const seleccionarPlantilla = (value) => {
    setplantilla(true);
    setUrl(value);
    if (value === "9" || value === "8") {
      document.getElementById("email-de").value = correoEstatico;
      document.getElementById("asunto").value = "ALERTA DE SEGURIDAD";
      setstate({
        ...state,
        ["email"]: correoEstatico,
        ["asunto"]: "ALERTA DE SEGURIDAD",
      });
    } else if (value === "10") {
      document.getElementById("email-de").value =
        "bcrmensajero@bcrseguridad.com";
      document.getElementById("asunto").value = "Alerta de Seguridad";
      setstate({
        ...state,
        ["email"]: "bcrmensajero@bcrseguridad.com",
        ["asunto"]: "Alerta de Seguridad",
      });
    } else if (value === "11") {
      document.getElementById("email-de").value =
        "bcrmensajero@bcrseguridad.com";
      document.getElementById("asunto").value = "Alerta de Seguridad";
      setstate({
        ...state,
        ["email"]: "bcrmensajero@bcrseguridad.com",
        ["asunto"]: "Alerta de Seguridad",
      });
    }
  };

  useEffect(() => {
    console.log(state);
  }, [state]);

  const sendEmailCloud = () => {
    var requestOptions = {
      method: "GET",
      mode: "cors",
      header: "Access-Control-Allow-Origin",
    };
    let someText = "";
    if (plantilla) {
      if (url === "8") {
        someText = "8";
      } else if (url === "9") {
        someText = "9";
      } else if (url === "10") {
        someText = "10";
      } else if (url === "11") {
        someText = "11";
      } else {
        someText = state["mensaje"].replace(/(\r\n|\n|\r)/gm, "<br>");
      }
    } else {
      someText = state["mensaje"].replace(/(\r\n|\n|\r)/gm, "<br>");
    }

    fetch(
      "https://us-central1-monkeyteams.cloudfunctions.net/mailgun-bridge?id=<p>" +
        someText +
        "</p>&imagen=" +
        state["url"] +
        "&subject=" +
        state["asunto"] +
        "&email=" +
        state["email"] +
        "&emailsending=" +
        state["correo"],
      requestOptions
    )
      .then(() => {
        Swal.fire("Email enviado correctamente");
        console.log(
          "https://us-central1-monkeyteams.cloudfunctions.net/mailgun-bridge?id=<p>" +
            someText +
            "</p>&imagen=" +
            state["url"] +
            "&subject=" +
            state["asunto"] +
            "&email=" +
            state["email"] +
            "&emailsending=" +
            state["correo"]
        );
      })
      .catch((error) => console.log("error", error));
  };

  const HanddleWrite = (name, value) => {
    setstate({ ...state, [name]: value });
  };

  return (
    <>
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Enviar Email
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Toda la información es necesaria para realizar el envio del
                correo de manera correcta.
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Para:
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        type="text"
                        name="company-website"
                        id="company-website"
                        className="focus:ring-indigo-500 focus:border-indigo-500 p-4 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                        placeholder="ejemplo@ejemplo.com"
                        onChange={(e) => HanddleWrite("correo", e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-gray-700"
                    >
                      De:
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        type="text"
                        id="email-de"
                        className="focus:ring-indigo-500 focus:border-indigo-500 p-4 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                        placeholder="ejemplo@ejemplo.com"
                        onChange={(e) => HanddleWrite("email", e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Asunto
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        type="text"
                        name="asunto"
                        id="asunto"
                        className="focus:ring-indigo-500 focus:border-indigo-500 p-4 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                        placeholder="ejemplo@ejemplo.com"
                        onChange={(e) => HanddleWrite("asunto", e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Mensaje a enviar
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="about"
                      name="about"
                      rows={3}
                      className="shadow-sm focus:ring-indigo-500 p-4 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                      placeholder="mensaje a enviar via correo"
                      onChange={(e) => HanddleWrite("mensaje", e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="location"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Plantilla
                  </label>
                  <select
                    id="location"
                    onChange={(e) => seleccionarPlantilla(e.target.value)}
                    name="location"
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    defaultValue="Canada"
                  >
                    <option value="1">Sin plantilla</option>
                    <option value="9">Plantilla M1</option>
                    <option value="8">Plantilla M2</option>
                    <option value="10">Plantilla M3</option>
                    <option value="11">Plantilla M4</option>
                  </select>
                </div>

                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Url de la imagen
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        type="text"
                        name="company-website"
                        id="company-website"
                        className="focus:ring-indigo-500 focus:border-indigo-500 p-4 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                        placeholder="ejemplo@ejemplo.com"
                        onChange={(e) => HanddleWrite("url", e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                  onClick={(e) => sendEmailCloud()}
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Enviar Email
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendEmail;