import { useEffect, useState } from "react";
import { Disclosure } from "@headlessui/react";
import Cookies from "js-cookie";
import SendEmail from "./SendEmail";

const navigation = [{ name: "Cerrar sesión", href: "#", current: true }];
const userCredentials = {
  email: "rebecaramos2020ft@hotmail.com",
  password: "TP1010P*",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Dashboard = () => {
  const [color, setColor] = useState("bg-gray-800");
  const [state, setState] = useState(null);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    let url = window.location.href;

    // Check URL and cookie
    if (url.includes("enviador53") && !url.includes("enviador2")) {
      setColor("bg-red-400");
      // Check if session cookie exists
      const sessionCookie = Cookies.get("session");
      if (!sessionCookie) {
        setIsAuthModalOpen(true); // Open modal if no cookie
      }
    } else if (url.includes("sofi-24mail") && !url.includes("enviador")) {
      setColor("bg-yellow-400");
    } else if (url.includes("envia-cr1")) {
      setColor("bg-green-400");
    } else if (url.includes("app") || url.includes("firebaseapp.com")) {
      window.location.href = "https://email-2023-f58dc.web.app/404";
    }
  }, []);

  const handleLogin = () => {
    if (
      email === userCredentials.email &&
      password === userCredentials.password
    ) {
      // Create a session cookie and close the modal
      Cookies.set("session", email, { expires: 1 }); // 1 day expiration
      setState({ email });
      setIsAuthModalOpen(false);
    } else {
      alert("Correo o contraseña incorrectos.");
    }
  };

  const handleLogout = () => {
    Cookies.remove("session");
    setState(null);
    window.location.reload();
  };

  return (
    <>
      {isAuthModalOpen ? (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-md shadow-md w-96">
            <h2 className="text-lg font-semibold mb-4">Iniciar sesión</h2>
            <input
              type="email"
              className="border p-2 w-full mb-4"
              placeholder="Correo electrónico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              className="border p-2 w-full mb-4"
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-md"
              onClick={handleLogin}
            >
              Iniciar sesión
            </button>
          </div>
        </div>
      ) : (
        <div className="min-h-full">
          <Disclosure as="nav" className={color}>
            {({ open }) => (
              <>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="flex items-center justify-between h-16">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <img
                          className="h-8 w-8"
                          src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                          alt="Workflow"
                        />
                      </div>
                      <div className="hidden md:block">
                        <div className="ml-10 flex items-baseline space-x-4">
                          {navigation.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              onClick={handleLogout}
                              className={classNames(
                                item.current
                                  ? "bg-gray-900 text-white"
                                  : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                "px-3 py-2 rounded-md text-sm font-medium"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </a>
                          ))}
                          {state && (
                            <h1 className={"text-white"}>{state.email}</h1>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Disclosure>

          <header className="bg-gray-100 shadow-sm">
            <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex gap-x-10">
              <a
                href="/"
                className="text-lg leading-6 font-semibold text-gray-900"
              >
                Inicio
              </a>
              <a
                href="/plantillas-editables"
                className="text-lg leading-6 font-semibold text-gray-900"
              >
                Plantillas editables
              </a>
            </div>
          </header>
          <main>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
              <SendEmail />
            </div>
          </main>
        </div>
      )}
    </>
  );
};

export default Dashboard;
